<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <h4 id="traffic" class="card-title mb-0">Contabilidad</h4>
            <div class="small text-muted">Administración de todo el proceso contable</div>
          </b-col>
        </b-row>
      </b-card>

      <b-row>
        <b-col lg="9">
          <b-row>
            <b-col lg="4">
              <b-row>
                <b-col lg="12">
                  <Widget :configWidget="configWidget" reference="list_unconfigured_data_business" :hasAccess="configWidget.elements.listUnconfiguredDataBusiness" />
                </b-col>
                <b-col lg="12">
                  <Widget :configWidget="configWidget" reference="list_unconfigured_accounts_default" :hasAccess="configWidget.elements.listUnconfiguredAccountsDefault" />
                </b-col>
              </b-row>
            </b-col>
            <b-col lg="8">
              <Widget :configWidget="configWidget" reference="bar_accounting_months" :hasAccess="configWidget.elements.barAccountingMonths" />
            </b-col>
          </b-row>
        </b-col>
        <b-col lg="3">
          <b-list-group class="mb-2">            
            <b-list-group-item href="#" class="flex-column align-items-start" @click="openAccountingEntries()">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">Movimientos</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                Adminsitración de todos los asientos
              </p>
            </b-list-group-item>
          </b-list-group>    

         <b-list-group>            
            <b-list-group-item href="#" class="flex-column align-items-start" @click="openAccountPlan()">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">Plan de Cuentas</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                Administración del Plan de Cuentas
              </p>
            </b-list-group-item>
          </b-list-group>                      

          <b-list-group>            
            <b-list-group-item href="#" class="flex-column align-items-start" @click="openSettings()">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">Configuración</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                Configuraciones generales
              </p>
            </b-list-group-item>
          </b-list-group>                     
        </b-col>        
      </b-row>

    </b-col>
  </b-row>
</template>
<script>
  //import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper'
  import Widget from '@/components/inc/widget/widget'

  export default {
    components: {
      Widget
    },
    data: () => {
      return {
        access: {
          module_id: Modules.CONTABILIDAD,
          profile_id: Profiles.PERSONAL,
          view_reference: 'dashboard',
          elements: {}
        },
        configWidget: {
          module_id: Modules.CONTABILIDAD,
          profile_id: Profiles.PERSONAL,
          elements: {
            barAccountingMonths: true,
            listUnconfiguredDataBusiness: true,
            listUnconfiguredAccountsDefault: true
          }
        },
        primaryColor: '',
      }
    },
    created () {
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */

      /* Configurar permisos de los widgets */      
      this.configWidget.elements.listUnconfiguredDataBusiness = Helper.hasAccessWidget(this.configWidget, 'list_unconfigured_data_business')
      this.configWidget.elements.listUnconfiguredAccountsDefault = Helper.hasAccessWidget(this.configWidget, 'list_unconfigured_accounts_default')
      this.configWidget.elements.barAccountingMonths = Helper.hasAccessWidget(this.configWidget, 'bar_accounting_months')
      /* Fin configuracion */
    },
    mounted() {
      this.setup()
    }, 
    methods: {
      setup () {        
        if(Session.getSession().settings) {    
          this.primaryColor = Session.getSession().settings.color_primary
                    
          this.createCSS()
        }
      },         
      createCSS() {
        var hoja = document.createElement('style')
        hoja.innerHTML = ""      
        hoja.innerHTML = hoja.innerHTML  + ".list-group-item-action:hover, .list-group-item-action:focus { background-color: " + this.primaryColor + "; }"

        document.body.appendChild(hoja);
      },
      openAccountingEntries() {
        this.$router.push({ name: 'AccountingStaffAccountingEntries' })
      },
      openSettings() {
        this.$router.push({ name: 'AccountingStaffSettings' })
      },
      openAccountPlan() {
        this.$router.push({ name: 'AccountingStaffAccountPlan' })
      }
    }    
   
  }
</script>

<style>

</style>
